import { useEffect, useState } from "react";
import Header from "../../components/header";
import SideBar from "../../components/sidebar";
import Footer from "../../components/footer";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import services from "../../services"
import constant from "../../constant";
const TaxiListing = () => {
    const navigate = useNavigate()
    const [addressList, setAddressList] = useState()
    const [addressList2, setAddressList2] = useState()
    const [selectedPickup, setSelectedPickup] = useState()
    const [selectedDrop, setSelectedDrop] = useState()
    const [carType, setCarType] = useState()
    const [tripType, setTripType] = useState()
    const [pickupDate, setPickupDate] = useState()
    const [typeFilter, setTypeFilter] = useState('all')
    const [masterCarData, setMasterCarData] = useState()
    const [vendorCarData, setVendorCarData] = useState()
    const [filterCarData, setFilterCarData] = useState()
    const [sharedCarData, setSharedCarData] = useState()
    const [duration, setDuration] = useState()
    const [totalKm, setTotalKm] = useState()
    const [vacant, setVacant] = useState()

    const GET_LOCATION_DETAILS = async(searchKey, type) => {
        
        try {
          let data = searchKey.replaceAll(' ','+')
          const response = await Axios.get(constant.BASE_URL+`/customer/getLocation?searchKey=${data}`);
          if(type == 'drop'){
              setAddressList2(response.data.features)

          }else {

              setAddressList(response.data.features)
          }
          //
       
        } catch (err) {
          console.log(err)
        }
     
    }
    const getMasterCarsDetails = async (data,type) => {
        if(data?.tripType == 'sharing' || tripType == 'sharing'){
            try {
                let pickup = {
                    lat: data ? data.selectedPickup.geometry.coordinates[1] + '' : selectedPickup.geometry.coordinates[1] + '',
                    lng: data ? data.selectedPickup.geometry.coordinates[0] + '' : selectedPickup.geometry.coordinates[0] + '',
                }
                let drop = {
                    lat: data ? data.selectedDrop.geometry.coordinates[1] + '' : selectedDrop.geometry.coordinates[1] + '',
                    lng: data ? data.selectedDrop.geometry.coordinates[0] + '' : selectedDrop.geometry.coordinates[0] + '',
                }
                let smdata = {
                    pickupLocation: pickup,
                    dropLocation: drop,
                    seatRequired : data ? +data.vacant : +vacant,
                    // tripStartDate: data ? data?.pickupDate :  pickupDate
                }
                
                const shareresponse = await services.order.SEARCH_SHARING(smdata);
                setSharedCarData(shareresponse.data)
            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                let pickup = {
                    lat: data ? data.selectedPickup.geometry.coordinates[1] + '' : selectedPickup.geometry.coordinates[1] + '',
                    lng: data ? data.selectedPickup.geometry.coordinates[0] + '' : selectedPickup.geometry.coordinates[0] + '',
                }
                let drop = {
                    lat: data ? data.selectedDrop.geometry.coordinates[1] + '' : selectedDrop.geometry.coordinates[1] + '',
                    lng: data ? data.selectedDrop.geometry.coordinates[0] + '' : selectedDrop.geometry.coordinates[0] + '',
                }
                let payload = {
                    pickupLocation: pickup,
                    dropLocation: drop,
                    // carType : data ? data.carType : carType,
                    vendorCar : true
                }
                console.log(type)
                // if(type == 'isVendor'){
                //     payload.vendorCar = true;
                //     setTypeFilter(null)
                // }
                if(payload.carType == 'all') {delete payload.carType}
                const response = await services.order.SEARCH_MASTER_CAR(payload)
                if(type == 'isVendor'){
                    setVendorCarData(response.data.availableCars)
                } else {
                    setMasterCarData(response.data.availableCars)
                    setFilterCarData(response.data.availableCars)
                }
                
                let thenum = response.data.distance/1000;
                setTotalKm(+thenum)
                const hours = Math.floor(response.data.duration / 3600);
const minutes = Math.floor((response.data.duration % 3600) / 60);
                setDuration(`${hours} hrs & ${minutes} mins`)
            } catch (error) {
                console.log(error)
            }
        }
    }
    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('data'))
        if (data) {
            setSelectedPickup(data.selectedPickup)
            setSelectedDrop(data.selectedDrop)
            setCarType(data.carType)
            setTypeFilter(data.carType);
            setTripType(data.tripType)
            setPickupDate(data.pickupDate)
            getMasterCarsDetails(data)
            setVacant(data.vacant)
        }
    }, [])
    const filterDataHandler = (type) => {
        setVendorCarData(null)
        setTypeFilter(type);
        if(type == 'all'){
            setFilterCarData(masterCarData)
        } else {
            var newArray = masterCarData.filter(function (el) {
                return el.Car.type == type;
              });
              setFilterCarData(newArray)
        }
    }
    const isLoggedIn = localStorage.getItem('access_token')
    const handleBooking = (item) =>{
        if(isLoggedIn){
            let data = {
                selectedDrop,
                selectedPickup,
                carType,
                tripType,
                pickupDate,
                duration,
                totalKm,
                item
            }
            if(tripType == 'sharing'){
                data.vacant = vacant;
                data.price = (((item.carData.perKmCharge*(item.distanceBreakdown.distance/1000))/((item.carData.customerCapacity-item.sharingAvailable)+(+vacant)))*vacant)
            }
        
            localStorage.setItem('data', JSON.stringify(data))
            navigate(`/booknow`)
        } else {
            let data = {
                selectedDrop,
                selectedPickup,
                carType,
                tripType,
                pickupDate,
                duration,
                totalKm,
                item,
               }
               if(tripType == 'sharing'){
                data.vacant = vacant;
                data.price = (((item.carData.perKmCharge*(item.distanceBreakdown.distance/1000))/((item.carData.customerCapacity-item.sharingAvailable)+(+vacant)))*vacant)
            }
            localStorage.setItem('data', JSON.stringify(data))
            navigate(`/login`)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">

            <div class="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                <div class="container py-5">
                    <h2 class="breadcrumb-title">Our Taxi</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Our Taxi</li>
                    </ul>
                </div>
            </div>
            <div className="booking-area ">
                <div className="container">
                    <div className="booking-form">
                        <h4 className="booking-title">Book Your Ride</h4>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Trip Type</label>
                                    <select value={tripType} onChange={(e)=>setTripType(e.target.value)} className="select-custom">
                                        <option value>Choose type</option>
                                        <option value="oneway">Oneway</option>
                                        <option value="twoway">Twoway</option>
                                        <option value="sharing">Sharing</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group" key={selectedPickup?.properties?.full_address}>
                                    <label>Pick Up Location</label>
                                    <input defaultValue={selectedPickup?.properties?.full_address} type="text" className="form-control" placeholder="Type Location" onChange={(e) => { e.target.value.length > 3 ? GET_LOCATION_DETAILS(e.target.value) : e.target.value.length < 1 && setAddressList() }} />
                                    <i className="far fa-location-dot"></i>
                                    {addressList?.length > 0 && <ul className="suggesting-dropdown">
                                        {addressList.map((item)=>{return (<><li onClick={() => { setSelectedPickup(item); setAddressList() }}>{item?.properties?.full_address}</li><hr/></>)})}
                                    </ul>}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group" key={selectedDrop?.properties?.full_address}>
                                    <label>Drop Off Location</label>
                                    <input defaultValue={selectedDrop?.properties?.full_address} type="text" className="form-control" placeholder="Type Location" onChange={(e) => { e.target.value.length > 3 ? GET_LOCATION_DETAILS(e.target.value, 'drop') : e.target.value.length < 1 && setAddressList2() }} />
                                    <i className="far fa-location-dot"></i>
                                    {addressList2?.length > 0 && <ul className="suggesting-dropdown">
                                        {addressList2.map((item)=>{return ( <><li onClick={() => { setSelectedDrop(item); setAddressList2() }}>{item?.properties?.full_address}</li><hr/></>)})}
                                    </ul>}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                {tripType == 'sharing' ? <div className="form-group">
                                    <label>Enter # of passengers</label>
                                    <input value={vacant} type="text" className="form-control" placeholder="Type passenger count" onChange={(e) => { setVacant(e.target.value)}} />
                                    <i className="far fa-users"></i>
                                   
                                </div> : <div className="form-group">
                                    <label>Cab Type</label>
                                    <select value={carType} onChange={(e)=>setCarType(e.target.value)} className="select-custom">
                                        <option value>Choose Cab</option>
                                        <option value="all">All Type</option>
                                        <option value="hatchback">Hatchback</option>
                                        <option value="sedan">Sedan</option>
                                        <option value="muv">MUV</option>
                                        <option value="suv">SUV</option>
                                        <option value="minibus">Mini bus</option>
                                    </select>
                                </div>}
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Pick Up Date</label>
                                    <input value={pickupDate} onChange={(e)=>setPickupDate(e.target.value)} type="datetime-local" className="form-control" placeholder="MM/DD/YY" />
                                    <i className="far fa-calendar-days"></i>
                                </div>
                            </div>



                            <div className="col-lg-4 align-self-end">
                                <button  disabled={!selectedDrop?.properties?.full_address} onClick={()=>getMasterCarsDetails()} className="theme-btn">Find Taxi<i
                                    className="fas fa-arrow-right"></i></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

          <div class="taxi-area py-120">
          {filterCarData && tripType != 'sharing' ?   
          <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline">Our Taxi</span>
                                <h2 class="site-title">Let's Check Available Taxi</h2>
                                <div class="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div class="filter-controls">
                        <ul class="filter-btns">
                            <li class={`${typeFilter == 'all' && 'active'}`} onClick={()=>filterDataHandler('all')}>All Cars</li>
                            <li class={`${typeFilter == 'sedan' && 'active'}`} onClick={()=>filterDataHandler('sedan')}>Sedan</li>
                            <li class={`${typeFilter == 'hatchback' && 'active'}`} onClick={()=>filterDataHandler('hatchback')}>Hatchback</li>
                            <li class={`${typeFilter == 'muv' && 'active'}`} onClick={()=>filterDataHandler('muv')}>MUV</li>
                            <li class={`${typeFilter == 'suv' && 'active'}`} onClick={()=>filterDataHandler('suv')}>SUV</li>
                            <li class={`${typeFilter == 'minibus' && 'active'}`} onClick={()=>filterDataHandler('minibus')}>Mini Bus</li>
                            {/* <li class={`${vendorCarData?.length > 0 && 'active'}`} onClick={()=>getMasterCarsDetails(null,'isVendor')}>All Cars</li> */}
                        </ul>
                    </div>
                    <div class="row filter-box" id="tax-area">
                       {filterCarData?.length > 0 && filterCarData?.map((item, index)=>( <div class="col-md-6 col-lg-4 filter-item cat1 cat2">
                            <div class="taxi-item" onClick={() => handleBooking(item)}>
                                <div class="taxi-img">
                                    <img src={constant.IMAGE_URL+(item?.photos ? item?.photos[0] : item?.Car.photos[0])} crossorigin="anonymous" style={{height:'350px', width:'100%', objectFit: 'cover'}}/>
                                </div>
                                <div class="taxi-content">
                                    <div class="taxi-head">
                                        <h4>{item.Car.brand} {item.Car.name}</h4>
                                        <span>Rs {item.Car.perKmCharge}/km</span> <br />
                                        <span>Estimated Price : <b>Rs. {tripType == 'twoway'? ((item.Car.perKmCharge*totalKm)*2).toFixed(0) : (item.Car.perKmCharge*totalKm).toFixed(0)}</b></span><br />
                                        <span>Estimated Time : <b>{duration} <small>(for oneway)</small></b></span>
                                    </div>
                                    <div class="taxi-feature">
                                        <ul>
                                            
                                            <li><i class="far fa-person-seat"></i> Passengers: <span>{item.Car.customerCapacity}</span></li>
                                            <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span><b>{item.isCarrierAvailable ? 'Yes' : 'No'}</b></span></li>
                                            <li><i class="far fa-heat"></i> Air Condition: <span><b>{item.isAcAvailable ? 'Yes' : 'No'}</b></span></li>
                                            <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <button onClick={() => handleBooking(item)} class="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>)) 
                        // : filterCarData?.map((item, index)=>( <div class="col-md-6 col-lg-4 filter-item cat1 cat2">
                        //     <div class="taxi-item" onClick={() => handleBooking(item)}>
                        //         <div class="taxi-img">
                        //             <img src={constant.IMAGE_URL+item.photos[0]} crossorigin="anonymous" style={{height:'350px', width:'100%', objectFit: 'cover'}}/>
                        //         </div>
                        //         <div class="taxi-content">
                        //             <div class="taxi-head">
                        //                 <h4>{item.brand} {item.name}</h4>
                        //                 <span>Rs {item.perKmCharge}/km</span> <br />
                        //                 <span>Estimated Price : <b>Rs. {tripType == 'twoway'? (item.perKmCharge*totalKm)*2 : item.perKmCharge*totalKm}</b></span><br />
                        //                 <span>Estimated Time : <b>{duration} <small>(for oneway)</small></b></span>
                        //             </div>
                        //             <div class="taxi-feature">
                        //                 <ul>
                                            
                        //                     <li><i class="far fa-person-seat"></i> Passengers: <span>{item.customerCapacity}</span></li>
                        //                     <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>4</span></li>
                        //                     <li><i class="far fa-heat"></i> Air Condition: <span>Yes</span></li>
                        //                     <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                        //                     </li>
                        //                 </ul>
                        //             </div>
                        //             <button onClick={() => handleBooking(item)} class="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></button>
                        //         </div>
                        //     </div>
                        // </div>))
                        }
                    </div>
                </div> : <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline">Our Taxi</span>
                                <h2 class="site-title">Let's Check Available Taxi</h2>
                                <div class="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row filter-box" id="tax-area">
                       {sharedCarData?.map((item, index)=>( <div class="col-md-6 col-lg-4 filter-item cat1 cat2">
                            <div class="taxi-item" onClick={() => handleBooking(item)}>
                                <div class="taxi-img">
                                    <img src={constant.IMAGE_URL+item.carData.photos[0]} crossorigin="anonymous"/>
                                </div>
                                <div class="taxi-content">
                                    <div class="taxi-head">
                                        <h4>{item.carData.brand} {item.carData.name}</h4>
                                        <span>Rs {item.carData.perKmCharge}/km</span> <br />
                                        <span>Estimated Price : <b>Rs. {(((item.carData.perKmCharge*(item.distanceBreakdown.distance/1000))/((item.carData.customerCapacity-item.sharingAvailable)+(+vacant)))*vacant).toFixed(0)}</b></span><br />
                                        
                                    </div>
                                    <div class="taxi-feature">
                                        <ul>
                                            
                                            <li><i class="far fa-person-seat"></i> Passengers Vacant: <span>{item.sharingAvailable}</span></li>
                                            <li><i class="far fa-suitcase-rolling"></i> Luggage Carry: <span>4</span></li>
                                            <li><i class="far fa-heat"></i> Air Condition: <span>Yes</span></li>
                                            <li><i class="far fa-map-location-dot"></i> GPS Navigation: <span>Yes</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <button onClick={() => handleBooking(item)} class="theme-btn">Book Taxi Now<i class="fas fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>))}
                    </div>
                </div>}
            </div>

        </main>
        <Footer />
    </>
    )
}
export default TaxiListing